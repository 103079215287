.about-container {
  text-align: center;
  /* padding: 50px 20px; */
  overflow-x: hidden;
 }
 
 
 .business-section {
  margin-bottom: 50px;
 }
 
 
 .business-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
 }
 
 
 .business-description {
  font-size: 1.1rem;
  line-height: 1.6;
  width: 80%;
  text-align: left;
  margin: 10px auto;
 }
 
 
 .divider{
  border: none;
  height: 1px;
  background-color: white;
  margin: 20px auto;
  width: 80%;
 }
 
 
 .creators {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  margin: 50px 10% 0;
 }
 
 
 .profileAndDetails {
  margin-bottom: 10%;
 }
 
 
 #photo-name-title {
  display: flex;
  justify-content: flex-start;
 }

 .linkedin-icon{
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
  background-color: white;
  border: none;
  border-radius: 5px;
 }

 .linkedin-icon:hover{
  cursor: pointer;
 }
 
 .creator {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 25%;
 }
 
 
 .creator-image {
  width: 158px;
  height: 150px;
  border-radius: 5%;
  margin-right: 20px;
  margin-bottom: 20px;
  display: block;
  box-shadow: 8px 8px 15px rgba(255,255,255,0.8);
 
 
  @media (max-width: 768px) {
    box-shadow: -8px 0 15px rgba(255, 255, 255, 0.8), 8px 0 15px rgba(255, 255, 255, 0.8), 0 -8px 15px rgba(255, 255, 255, 0.8);
  }
 }
 
 .creator-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
 }

 .creator-name {
  margin: 0;
  font-size: 1.5rem;
  text-wrap: wrap;
 }
 
 .creator-role {
  padding-bottom: 10px;
  margin: 0 0 10px;
  font-size: 1.2rem;
  color: #888;
 }
 
 .creator-description {
  font-size: 1rem;
  margin-top: 0;
  padding-bottom: 10px;
  line-height: 1.6;
  text-align: left;
  border-bottom: 1px solid white;
 }
 
 .image-container{
  float: left;
  max-height: 170px;
  max-width: 150px;
  justify-content: flex-start;
  margin-right: 20px;
 }
 
 
 @media (max-width: 768px) {
  .creators {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
  }
 
  .creator {
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }

  .linkedin-icon{
    margin: 10px auto;
  }
 
  #photo-name-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
 
  .image-container {
    margin: 0 0 20px 0;
  }
 
  .creator-details {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
 
  .creator-role {
    /* border-bottom: 1px solid white; */
    margin: 0;
  }
 
  .creator-image {
    margin: 0;
  }
 }
 
 
 @media (prefers-color-scheme: light) { 
  .divider{
    background: rgb(150, 150, 150);
  }
 
  .creator-image {
    box-shadow: 8px 8px 15px rgba(0, 0, 0,0.8);
  }
 
  .creator-role {
    border-bottom: 1px solid rgb(150, 150, 150);
    color: #888;
  }
 
  .creator-description {
    border-bottom: 1px solid rgb(150, 150, 150);
  }
 }
 
 