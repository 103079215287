.verification-message-box{
   height: auto;
   max-width: 570px;
   width: auto;
   padding: 20px;
   border: none;
   border-radius: 15px;
   margin: 20px auto;
   background: #1b1b1d;
   width: 70%;
   color: white;
}

.email-verification-title{
   font-size: 30px;
   border: 0;
   border-bottom: 3px solid #ffffff;
}

.verification-next-step{
   color: rgb(156, 156, 156);
}

.verification-to-profile-button-student{
   background: linear-gradient(71.15deg, var(--c1, #ff5f6d) 13.7%, var(--c2, #ffc371) 86.86%) var(--x, 0)/200%;
   color: white;
   transition: 0.5s;
}

.verification-to-profile-button-student:hover{
   --x: 100%;
   --c1: #ffc371;
   --c2: #ff5f6d;
}

.verification-to-profile-button-business{
   background: linear-gradient(71.15deg, var(--c1, #a18cd1) 13.7%, var(--c2, #fbc2eb) 86.86%) var(--x, 0)/200%;
   color: white;
   transition: 0.5s;
}

.verification-to-profile-button-business:hover{
   --x: 100%;
   --c1: #fbc2eb;
   --c2: #a18cd1;
}

.verification-to-profile-button{
   height: 50px;
   width: 200px;
   border: none;
   border-radius: 10px;
   font-size: 17px;
}

.verification-to-profile-button:hover{
   cursor: pointer;
}

@media (prefers-color-scheme: light) {
   .verification-message-box{
      height: auto;
      max-width: 570px;
      padding: 20px;
      border: none;
      border-radius: 15px;
      margin: 20px auto;
      width: 70%;
      color: black;
      background-color: white;
   }

   .email-verification-title{
      font-size: 30px;
      border: 0;
      border-bottom: 3px solid rgb(150, 150, 150);
      color: black;
   }

   .verification-next-step{
      color: rgb(49, 49, 49);
   }
   .verification-to-profile-button {
      color: black;
   }
}