.business-profile-box{
   width: 80vw;
   max-width: 450px;
   height: auto;
   align-items: center;
   align-content: center;
   border-radius: 15px;
   padding-left:25px;
   padding-right: 25px;
   padding-bottom: 20px;
   margin: 40px auto;
   background-color: #1b1b1d;;
}

#money-container {
   display: flex;
   align-items: center;
}

#money-symbol {
   margin-top: 15px;
   margin-right: 10px;
}

.long-input{
   margin-bottom: -7px; /* to fix text-box over extend gap at bottom */
   resize: none;
   height: 100px;
   font-family: "Roboto","Helvetica","Arial",sans-serif;
 }

.business-profile-h1{
   margin-top: 41.440px;
   margin-bottom: -18px;
}

.input-field-box::placeholder{
   color: #ccc;
   font-size: 16px;
}

.bus-hide-scrollbar {
   scrollbar-width: none;
   -ms-overflow-style: none;
}

.bus-custom-scrollbar {
   scrollbar-width: thin;
   scrollbar-color: grey #1b1b1d;
}

.student-profile-button{
   width: 100%;
   height: 50px;
   border-radius: 12px;
   margin-top: 15px;
   border: none;
   background-color: #007bff;
   font-size: 16px;
   color: #fff;
}

.student-profile-button:hover{
   background-color: #0056b3;
   cursor: pointer;
}

#money-sign {
   display: flex;
   flex-direction: row;
}

#money-character {
   margin-top: 25px;
   margin-right: 10px;
}

.businessType{
   width: 35px;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   border: 1px solid grey;
}

#demo-simple-select {
   background-color: #2c2c2e;
   border-radius: 12px;
}

.icons{
   width: 100%;
   height: 100%;
   max-width: 50px;
   max-height: 50px;
}

.statusBlock{
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}

.statusIcon{
   margin: 0 5px;
}

.business-type-dropdown-container{
   margin-top: 10px;
}

@media (prefers-color-scheme: light) {
   .business-profile-box{
      width: 450px;
      height: auto;
      align-items: center;
      align-content: center;
      border-radius: 15px;
      padding-left:25px;
      padding-right: 25px;
      padding-bottom: 20px;
      margin: 40px auto;
      background-color: white;
   }

   .input-field-box{
      width: 100%;
      height: 50px;
      border-radius: 12px;
      padding-top: 5px;
      border: 1px solid grey;
      margin-top: 15px;
      padding: 10px;
      background-color: white;
      color: black;
   }

   .input-field-box::placeholder{
      color: #898989;
   }

   .input-field-box:focus{
      outline: 1px solid rgb(0, 157, 255);
   }
   
   .student-profile-button{
      width: 100%;
      height: 50px;
      border-radius: 12px;
      margin-top: 15px;
      border: none;
      background-color: #007bff;
      font-size: 16px;
      color: white;
   }

   .student-profile-button:hover{
      background-color: #0a45c3;
      cursor: pointer;
   }

   .bus-custom-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: lightgrey white;
   }
}