#explore-page-body {
    margin: 0 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: start;
    align-items: start;
    gap: 10px;
    transition: all 0.3s;
}

#explore-page-body > * {
    min-width: 350px;
    max-width: calc(32vw - 10px);
    width: 1000px;
}

@media (max-width: 1121px) {
    #explore-page-body{
        grid-template-columns: repeat(2, 1fr);
    }

    #explore-page-body > * {
        max-width: calc(49vw - 10px);
    }
}

@media (max-width: 750px) {
    #explore-page-body{
        grid-template-columns: repeat(1, 1fr);
    }

    #explore-page-body > * {
        max-width: calc(99vw - 10px);
    }
}

@media(max-width: 400px) {
    #explore-page-body > * {
        min-width: 0;
    }
}