#forgot-password-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    min-width: 360px;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #1b1b1d;
   
}

#forgot-password-component {
    
    padding: 10px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#submit-message {
    margin-top: 10px;
}


#subheading-password-reset {
    color: grey;
    margin-top: 0;
}

.reset-pass-button {
    margin-top: 15px;
    margin-bottom: 21.440px;

    padding: 12px;
    background-color: var(--c, #1990ff);
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

#reset-account-pass {
    margin-bottom: 0px;
}

#subheading-password-reset {
    margin-top: 13px;
    margin-bottom: 25px;
}








@media (prefers-color-scheme: light) {
    #forgot-password-parent {
        background-color: white;
    }
}