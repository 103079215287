.alert{
   margin: 20px auto;
   width: 400px;
   max-width: 70%;
}

.accepted-alert{
  width: 428px;
  height: auto;
  z-index: 5;
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.update-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Email-Reachout-Message{
  font-size: 20px;
}

.applyAlert {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 3;
  margin: 0;
  width: 600px;
  max-width: 300px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
 }

 .alert-container-apply{
  z-index: 3;
  width: 100%;
  top: 100px;
  position: fixed;
}

.blur-background{
  filter: blur(5px); /* Add blur effect to the background */
  transition: filter 0.3s; /* Disable pointer events on the background */
}

@media (max-width: 530px){
  .Email-Reachout-Message{
    font-size: 16px;
  }

  .accepted-alert{
    width: 80vw;
    right: 50%;
    transform: translateX(50%);
  }
}
