@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.home-container *{
  transition: all 0.3s ease;
}

.home-container img{
  transition: all 2s ease;
}

body {
  margin: 0;
  padding: 0;
  background-color: #1b1b1b;
  transition: background-color 0.5s ease;
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
}

.home-container {
  display: flex;
  flex-direction: column;
  transition: background-color 0.5s ease;
}

.white-text {
  color: white;
}

.hero-section,
.benefits-section,
.features-section{
  background-color: #1b1b1b;
  opacity: 1;
}

.problem-solution-section,
.testimonials-section,
.faq-section {
  background-image: linear-gradient(135deg, #1b1b1b 0%, #2b2e35 100%);
  opacity: 1;
}

.section-content {
  transform: translateY(10px);
  transition: transform 0.3s ease-out;
}

.section-content.show {
  transform: translateY(0);
}

.hero-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 90vh;
  padding: 100px 50px;
  box-sizing: border-box;
  position: relative;
}

.hero-section .section-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text-content {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headline {
  font-size: 5em;
  text-align: left;
  line-height: 1.2;
  margin: 2rem 0 0.5rem 0;
  white-space: nowrap;
}

.headline-content {
  display: inline-flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.subtext {
  color: lightgrey;
  font-size: 1.5em;
  font-weight: 300;
  text-align: left;
  margin: 0 0 0.25em 0;
  width: 100%;
  white-space: nowrap;
}

.slide-text-container {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  max-width: 100%;
  overflow: hidden;
  white-space: balance;
  text-align: left;
}

.slide-text {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.5s, transform 0.5s;
  width: 100%;
}

.slide-text.active {
  opacity: 1;
  transform: translateY(0);
  width: 100%;
}

.button-container {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.gradient-button {
  padding: 0.7em 1em;
  background-color: #1b1b1b;
  border: none;
  color: white;
  border-radius: 1000px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  font-size: 1.2em;
  font-weight: 450;
  z-index: 1;
  font-family: "Inter", sans-serif;
}

.gradient-button::after {
  content: "";
  position: absolute;
  height: 108%;
  width: 104%;
  border-radius: 1000px;
  background-image: linear-gradient(to bottom right, #bfa4f0, #7fc1f7);
  z-index: -1;
}

.gradient-button:hover {
  z-index: 0;
  box-shadow: 10px 0 25px #bfa4f0, -10px 0 25px #4891f0;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 50%;
}

.homepageImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 600px;
  mask-image: none;
}

.hero-image {
  width: 100%;
  max-width: 650px;
  object-fit: cover;
  margin-top: 50px;
  margin-left: 50px;
  mask-image: none;
}

.problem-solution-section {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;
  padding: 100px;
  position: relative;
}

.problem-solution-section .section-content {
  display: flex;
  width: 100%;
  align-items: center;
}

.video-container {
  flex: 1;
  margin-right: 50px;
}

.video-placeholder {
  width: 100%;
  margin: 0 auto;
  height: 0;
  padding-bottom: 56.25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #666;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 25px #8157b7;
}

.walk-through-video{
  position: absolute;
  top: 0;
  height: 100%;
}

.problem-solution-section .text-content {
  flex: 1;
  text-align: left;
  padding-left: 5%;
}

.problem-solution-section .section-title {
  font-size: 3em;
  color: #8157b7;
}

.problem-solution-section p {
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.5;
  color: white;
}

.problem-solution-section .main-point {
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.5;
  color: white;
  margin-top: 10px;
}

.benefits-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 30px 100px;
  background-color: #1b1b1b;
}

.benefits-section .section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 3em;
  color: #4891f0;
}

.benefits-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.benefit {
  width: 30%;
  height: 400px;
  text-align: center;
  padding: 0 60px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.benefit-content{
  padding: 5px 10px;
  margin: 5px auto;
}

.benefit:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.benefit.center {
  order: 2;
}

.benefit-icon {
  width: 100px;
  height: 100px;
}

.benefit-title {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #4891f0;
}

.benefit-description {
  font-size: 1em;
  line-height: 1.5;
  color: #ddd;
  margin-bottom: 0;
}

.testimonials-section {
  display: flex;
  justify-content: center;
  text-align: center;
  height: auto;
  padding: 40px 100px 70px 100px;
  min-height: 100vh;
  background-color: #2b2e35;
  position: relative;
}

.testimonial-section .section-title {
  font-size: 3em;
  color: #8157b7;
}

.testimonial-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
}

.testimonial-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.testimonial-quote {
  flex-grow: 1;
  font-size: 1.3em;
  color: white;
  margin-bottom: 50px;
  line-height: 1.6;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

.testimonial-info {
  display: flex;
  align-items: center;
}

.testimonial-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.testimonial-name {
  font-size: 1.2em;
  color: #8157b7;
  text-align: center;
}

.testimonial-stars {
  width: 100px;
  height: auto;
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
  margin-top: -25px;
  display: block;
}

.feature-item {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
}

.feature-item .subtext-feature{
  font-size: 1.3em;
  margin: 0;
}

.features-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10vw;
  margin: 10px;
}

.features-section {
  padding: auto;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-section-title {
  font-size: 3em;
  color: #4891f0;
  margin-bottom: 50px;
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  flex-shrink: 0;
}

.section-title {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: white;
}

.faq-section {
  padding: 100px;
  min-height: 100vh;
  background-color: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-section .section-title {
  font-size: 3em;
  text-align: center;
  margin-bottom: 50px;
  color: #8157b7;
}

.faq-container {
  width: 650px;
  margin: 0 auto;
}

.faq-item {
  border: 1px solid #2a2f3b;
  overflow: hidden;
  text-align: left;
}

.faq-question {
  background: #1b1b1b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #2a2f3b;
}

.faq-question h2 {
  margin: 0;
  font-size: 1.1em;
  color: white;
}

.faq-toggle {
  font-size: 1.5em;
  color: #a18cd1;
}

.faq-answer {
  padding: 20px;
  background-color: #2a2f3b;
  font-size: 1em;
  color: #ddd;
  line-height: 1.6;
}

.faq-item.open .faq-question {
  background-color: #2a2f3b;
}

.dropdown-arrow{
  margin-left: 2px;
}

.chevron-arrow{
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media (max-width: 1400px) {
  .hero-section {
    padding: 80px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
  }

  .sliding-text-container{
    text-align: center;
  }

  .hero-section .section-content {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    max-width: 100%;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .headline {
    text-align: center;
    font-size: 4em;
  }

  .subtext {
    text-align: center;
  }

  .button-container {
    justify-content: center;
  }

  .hero-image {
    width: 100%;
    max-width: 650px;
    object-fit: cover;
    margin-top: 50px;
    margin-left: 0;
  }

  .problem-solution-section {
    padding: 50px;
    padding-bottom: 100px;
  }

  .problem-solution-section .section-content {
    flex-direction: column;
  }

  .video-container {
    margin: 30px auto;
    margin-top: 60px;
    width: 80vw;
  }

  .problem-solution-section .text-content {
    width: 100%;
    padding: 0 5%;
  }

  .problem-solution-section .section-title {
    margin-left: auto;
    margin-right: auto;
  }

  .benefits-section {
    padding: 100px;
  }

  .benefits-container {
    flex-direction: column;
    align-items: center;
  }

  .benefit {
    width: 100%;
    max-width: 400px;
  }

  .benefit.center {
    order: 0;
  }

  .features-section {
    padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 30px;
  }

  .headline {
    font-size: 3em;
  }

  .subtext {
    font-size: 1.2em;
    text-wrap: wrap;
  }

  .benefits-section {
    padding: 50px;
    position: relative;
  }

  .benefits-section .section-title {
    font-size: 2em;
  }

  .faq-section {
    padding: 50px;
  }

  .faq-answer,
  .faq-question,
  .faq-item,
  .faq-container{
    width: 80vw;
  }

  .features-flex {
    flex-direction: column;
    gap: 0;
    margin: 10px;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .feature-item .subtext-feature{
    font-size: 1.4em;
  }

  .testimonial-grid {
    flex-direction: column;
    align-items: center;
  }

  .testimonial {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 20px;
  }

  .headline {
    font-size: 2.5em;
    margin: 0.5rem 0;
  }

  .subtext {
    font-size: 1em;
    margin-bottom: 0.25rem;
  }

  .slide-text-container {
    font-size: 0.8em;
    margin-bottom: 0.5rem;
  }

  .gradient-button {
    font-size: 0.9em;
    padding: 0.5em 0.8em;
  }

  .hero-image {
    margin-top: 20px;
  }

  .problem-solution-section .section-title,
  .testimonials-section .section-title,
  .faq-section .section-title {
    font-size: 1.8em;
  }

  .problem-solution-section {
    padding: 30px;
  }

  .problem-solution-section .text-content {
    max-width: 100%;
  }

  .benefits-section {
    padding: 30px;
  }

  .benefits-section .section-title {
    font-size: 1.8em;
  }

  .benefit-title {
    font-size: 1.2em;
  }

  .benefit-description {
    font-size: 0.9em;
  }

  .benefit-icon {
    width: 80px;
    height: 80px;
  }

  .faq-section {
    padding: 30px;
  }

  .faq-question h3 {
    font-size: 1em;
  }

  .faq-answer {
    font-size: 0.9em;
  }
}

@media (prefers-reduced-motion: reduce) {
  .section-content,
  .slide-text,
  .gradient-button,
  .benefits-section,
  .benefit {
    transition: none;
    animation: none;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: white;
  }

  .headline {
    color: black;
    padding-top: 50px;
  }

  .subtext {
    color: rgb(49, 49, 49);
  }

  .hero-section,
  .benefits-section,
  .features-section{
    background-color: white;
  }

  .problem-solution-section,
  .testimonials-section,
  .faq-section {
    background-image: linear-gradient(135deg, white 0%, #f0f0f0 100%);
    color: black;
  }

  .testimonial-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    align-items: flex-end;
  }

  .testimonial-quote {
    color: black;
  }

  .problem-solution-section .main-point,
  .problem-solution-section p,
  .benefit-description,
  .section-title,
  .faq-question h3,
  .faq-answer {
    color: black;
  }

  .benefit-icon {
    fill: rgb(161, 140, 209);
  }

  .dropdown-arrow {
    filter: invert(1) !important;
  }

  .gradient-button {
    background-color: white;
    color: white;
  }

  .gradient-button:hover {
    box-shadow: 5px 0 15px #8157b7, -5px 0 15px #4891f0;
  }

  .faq-container {
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1);
  }

  .faq-item {
    border-color: #e0e0e0;
  }

  .faq-question h2{
    color: black;
  }

  .faq-question {
    background-color: white;
  }

  .faq-question:hover,
  .faq-item.open .faq-question,
  .faq-item.open .faq-answer {
    background-color: #f5f5f5;
  }

  .faq-answer{
    background-color: #f5f5f5;
  }

  .benefit {
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .benefit:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }

  .benefit-title {
    color: #4891f0;
  }
}
