.footer {
  position: relative;
  bottom: 0;
  width: 100vw;
  padding: 20px 0;
  background-color: #333;
} 

.footer p {
  font-size: 0.9rem;
  color: #fff;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.footer-links li {
  display: inline;
  margin-right: 15px;
}

.footer-links li a {
  color: #fff;
  text-decoration: none;
}

.footer-links li a:hover {
  text-decoration: underline;
}

@media (prefers-color-scheme: light) {
	.footer {
		padding: 20px 0;
		background-color: white;
	}

  .footer p, .footer-links li a  {
    color: black;
  }


}