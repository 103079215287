.apply-title{
   color: white;
   font-size: 30px;
   text-align: left;
}

.applyForm-circular-progress{
   margin: 0 auto;
}

.title-container{
   max-width: 70%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 30px;
   background-color: white;
   margin-top: 40px;
   border-radius: 10px;
   padding: 25px;
   position: relative;
}

#right-panel {
   background-color: white;
   border-radius: 10px;
   padding: 25px;
   width: 503.27px;
   margin-top: 40px;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}


.make-grow {
   flex: 1;
}

#company-description-apply-form-page {
   text-align: left;
}

.turn-row {
  display: flex;
   flex-direction: row;

}


#apply-title {
   text-align: center;
   
}



#left-and-right-panels {
   margin: 5vh 3vw;
   display: flex;
   flex-direction: row;
   gap: 10px;
   justify-content: space-around;
   margin-top: 0;
}

.title-container, #right-panel {
   box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
   
}

#company-name {
   text-align: left;
}

.title{
   align-content: center;
}

#shove-to-bottom {
   margin-top: auto;
} 

#business-type-icon-text, #location-icon-text {
   display: flex;
   align-items: center;

}

.right-margin {
   margin-right: 15px;
}

#company-applying {
   font-size: 30px;
   background: linear-gradient(71.15deg, var(--c1, #a18cd1) 13.7%, var(--c2, #fbc2eb) 86.86%) var(--x, 0)/200%;
   background-clip: text;
   -webkit-background-clip: text;
   color: transparent;
}

.apply-form{
   display: flex;
   position: relative;
   justify-content: center;
   flex-direction: column;
   margin: 10px auto;
   background-color: #1b1b1d;
   max-width: 500px;
   width: auto;
   padding: 20px 10px;
   border-radius: 15px;
}

.to-apply{
   text-align: left;
   background-color: #1b1b1d;
   color: white;
   border: 1px solid white;
   border-radius: 8px;
   padding: 20px 5px;
   height: 10px;
   margin: 10px 3%;
   min-width: 400px; 
   align-self: center;
}

.to-apply:focus{
   outline: 1px solid rgb(0, 157, 255);
}

#submit-button{
   height: 45px;
   width: 40%;
   margin: 10px auto;
   background: linear-gradient(71.15deg, var(--c1, #ff5f6d) 13.7%, var(--c2, #ffc371) 86.86%) var(--x, 0)/200%;
   color: white;
   transition: 0.5s;
   border: none;
   border-radius: 8px;
   font-weight: bold;
   padding: 10px 20px;
   font-size: 20px;
   text-align: center;
}

#submit-button:hover{
   --x: 100%;
   --c1: #ffc371;
   --c2: #ff5f6d;
   cursor: pointer;
}

#divider{
   border: none;
   height: 1px;
   background-color: white;
   margin: 20px auto;
   width: 80%;
}

.resume-label{
   color: lightgrey;
   font-size: 20px;
}

#resume::-webkit-file-upload-button{
   background: transparent;
   padding: 10px 15px;
   border: 1px solid white;
   border-radius: 8px;
   color: grey;
   transition: all 0.3s;
}

#resume::-webkit-file-upload-button:hover{
   background: #424242;
   color: white;
   cursor: pointer;
   border: 1px solid transparent
}

#resume{
   color: grey;
   width: 50%;
}

.resume-upload-container{
   display: flex;
   justify-items: center;
   width: 100%;
   padding: 0 25px;
   gap: 100px;
   margin: 10px 3%;
}

.file-button{
   padding: 10px 15px;
   border: none;
   border-radius: 8px;
   color: white;
   background: linear-gradient(71.15deg, var(--c1, #ff5f6d) 13.7%, var(--c2, #ffc371) 86.86%) var(--x, 0)/200%;
   transition: 0.5s;
}

.file-button:hover{
   --x: 100%;
   --c1: #ffc371;
   --c2: #ff5f6d;
   cursor: pointer;
}

.applyFormTextField {
   input:-webkit-autofill,
   input:-webkit-autofill:hover,
   input:-webkit-autofill:focus,
   input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #1b1b1d inset;
      box-shadow: 0 0 0 30px #1b1b1d inset;
      caret-color: grey;
      background-color: transparent;
   }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (prefers-color-scheme: light) {
   .apply-title{
      color: black;
      font-size: 30px;
      text-align: left;
   }
   
   .apply-form{
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      margin: 10px auto;
      background-color: white;
      max-width: 500px;
      width: auto;
      padding: 20px 10px;
      border-radius: 15px;
   }
   
   .to-apply{
      text-align: left;
      background-color: white;
      color: black;
      border: 1px solid grey;
      border-radius: 8px;
      padding: 20px 5px;
      height: 10px;
      margin: 10px 3%;
      min-width: 400px; 
      align-self: center;
   }
   
   #divider{
      /* border: none;
      height: 1px;
      background-color: white;
      margin: 20px auto;
      width: 80%; */

      /* looks cleaner, could change */
      display: none;
   }

   .resume-label{
      color: black;
      font-size: 20px;
   }
   
   #resume::-webkit-file-upload-button{
      background: transparent;
      padding: 10px 15px;
      border: 1px solid grey;
      border-radius: 8px;
      color: grey;
      transition: all 0.3s;
   }
   
   #resume::-webkit-file-upload-button:hover{
      background: lightgrey;
      color: black;
      cursor: pointer;
      border: 1px solid transparent;
   }

   .applyFormTextField {
      /* Prevent background color change on autofill */
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
         -webkit-box-shadow: 0 0 0 30px white inset ;
         box-shadow: 0 0 0 30px white inset;
         caret-color: grey;
         background-color: transparent;
      }
   }
}

@media (max-width: 803px) {
   #left-and-right-panels {
      margin: 0;
      margin-bottom: 5vh;
      flex-direction: column;
      align-items: center;
   }

   #right-panel {
      width: 80vw;
   }

   .title-container {
      max-width: 80vw;
      width: 80vw;
   }
}

@media (prefers-color-scheme: dark) {
   .title-container, #right-panel {
      background-color: #1b1b1d
   }
}

#horizontal-stepper-container {
   display: flex;
   justify-content: center;
}