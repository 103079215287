.student-profile-h1{
   color: rgb(251, 245, 245);
   margin-top: 5px;
 }

 .profile-box-one{
   display: flex;
   width: 350px;
   height: auto;
   margin: 30px auto;
   padding: 20px;
   border-radius: 15px;
   background-color: #1b1b1d;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }

 .student-profile-input-box{
   padding: 12px;
   margin-top: 15px;
   width: 100%;
   font-size: 16px;
   background-color: rgba(249, 240, 240, 0.1);
   border: 1px solid #ccc;
   border-radius: 12px;
   color:#ffffff;
 }

 .long-input{
   margin-bottom: -7px; /* to fix text-box over extend gap at bottom */
   resize: none;
   height: 100px;
   font-family: "Roboto","Helvetica","Arial",sans-serif;
 }

 .student-profile-input-box::placeholder{
   color: #ccc;
 }

 .student-profile-update-button{
   background: #007bff;
   margin-top: 8px;
   font-size: 16px;
   color: #fff;
   width: 100%;
   height: 50px;
   border-radius: 12px;
   border: none;
   align-items: center;
   cursor: pointer;
 }

.stu-hide-scrollbar {
   scrollbar-width: none;
   -ms-overflow-style: none;
}

.stu-custom-scrollbar {
   scrollbar-width: thin;
   scrollbar-color: grey #1b1b1d;
}

 .student-profile-update-button:hover{
   background-color: #0056b3;
 }

 .student-profile-update-form{
   width: 100%;
 }

 @media (prefers-color-scheme: light) {
   .profile-box-one {
      background-color: white;
   }

   .student-profile-h1 {
      color: black;
   }

   .student-profile-input-box {
      background-color: white;
      color: black;
   }

   .stu-custom-scrollbar {
      scrollbar-width: thin;
      scrollbar-color: lightgrey white;
   }
 }