#myapplications-page-body {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the gap between cards */
    justify-content: center;
}

#myapplications-page-body Loading{
   margin: 0 auto;
}

#table-container {
    margin: auto;
    display: inline-block;
    overflow-x: auto;
}

.no-apps-yet {
   width: 50%;
   background-color: #1b1b1d;
   padding: 20px;
   border-radius: 12px;
}

.text-left {
   text-align: center;
   font-size: 20px;
}

.resume-preview-styling{
   display: block;
    width: 80%;
    height: 700px;
    margin: 10px auto;
    border: none;
 }
 
.resume-buttons:hover{
   cursor: pointer;
}

.preview-icon{
   margin-right: 2px;
}

.download-icon{
   margin-left: 2px;
}
.infoIcon{
   margin-left: 6px;
}

.alert-icon-application{
   margin: 0 auto;
}

.update-message{
   display: flex;
   align-items: center;
}

.update-message p{
   margin: 0;
}

#explore-button{
   margin-top: 20px;
   margin: 0 auto;
   border: none;
   width: 200px;
   height: 50px;
   background: linear-gradient(71.15deg, var(--c1, #4891f0) 13.7%, var(--c2, #7fc1f7) 86.86%) var(--x, 0)/200%;
   border-radius: 10px;
   color: black;
   font-size: 21px;
   transition: all 0.3s ease;
}

#explore-button:hover{
   --x: 100%;
   --c1: #7fc1f7;
   --c2: #4891f0;
   cursor: pointer;
}

@media (prefers-color-scheme: light) {
   #demo-simple-select {
      background-color: white;
   }

   .no-apps-yet {
      background-color: white;
   }
}

