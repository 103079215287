.App {
  text-align: center;
  min-height: calc(
    100vh - 93px
  ); /* 93px from padding on body to avoid router height */
  display: flex;
  flex-direction: column;
}

.app-body {
  /* margin-top: 50px; */
  flex: 1;
  background-color: black;
}

/*Default to dark mode*/
body {
  background-color: #1b1b1b;
  color: white;
  font-family: "Inter", sans-serif;
}

.App-menu-bar .active {
  color: white;
  padding-bottom: 5px;
  border-bottom: 3px solid white;
}

#sidebar .active {
  color: white;
  padding-bottom: 5px;
  border-bottom: 3px solid white;
}

#side-nav {
  display: flex;
  gap: 50px;
  vertical-align: middle;
  align-items: center;
  white-space: nowrap;
}

#first {
  margin-right: auto;
}

.App {
  margin-top: 10vh;
}

.App .App-menu-bar .login-button {
  color: white;
  transition: all 0.3s;
}

.App-menu-bar {
  /* eliminate bullet points */
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  padding-right: 40px;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(27, 27, 29, 0.8);
  position: fixed;
  width: 100%;
  gap: 100px;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.App-menu-bar a {
  color: rgb(156, 156, 156);
  text-decoration: none;
  transition: color 0.5s;
}

#sidebar a {
  color: rgb(156, 156, 156);
  text-decoration: none;
  transition: color .5s;
}

.App-link {
  color: #61dafb;
}

.login-button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 8px 24px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  line-height: normal;
  transition: all 0.3s ease-in-out;
}

.login-button:hover {
  border-color: transparent;
  background-image: linear-gradient(to bottom right, #bfa4f0, #7fc1f7);
  -webkit-text-fill-color: white;
  transform: scale(1.1);
}


#logo {
  width: 100px;
  height: auto;
  vertical-align: middle;
}

.button:hover {
  text-decoration: none;
}

.container {
  width: auto;
}

#signout {
  padding: 8px 24px;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  transition: all .3s;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  line-height: normal;
}

#signout:hover {
  border-color: transparent;
  background-image: linear-gradient(to bottom right, #bfa4f0, #7fc1f7);
  color: #fff;
  transform: scale(1.1);
}

.hideOnWide {
  display: none;
}

.hideOnThin {
  display: block;
}

#sidebar {
  display: none;
  padding-top: 50px;
  padding-right: 30px;
  gap: 50px;
  transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;
}

.sidebarOpen:hover{
  cursor: pointer;
}

.sidebar-close:hover{
  cursor: pointer;
}

.menu-icon{
  height: 24px;
}

@media (max-width: 1325px) {
  .hideOnWide {
    display: block; 
  }

  .hideOnThin {
    display: none;
  }

  #first {
    margin-right: auto;
  }

  #side-nav {
    flex-direction: column;
    gap: 15px;
  }

  #sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    z-index: 999;
    background-color: rgba(27, 27, 29);
    box-shadow: -10px 0 10px black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;
  }
}

.burger-icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
    font-family: 'Inter', sans-serif;
  }

  .app-body {
    background-color: #f0f0f2;
  }

  .App-menu-bar .active {
    color: black;
    padding-bottom: 5px;
    border-bottom: 3px solid black;
  }

  #sidebar .active {
    color: black;
    padding-bottom: 5px;
    border-bottom: 3px solid black;
  }

  .App .App-menu-bar .login-button {
    color: black;
    transition: all .3s;
  }

  .App-menu-bar {
    list-style-type: none;
    margin: 0;
    padding: 0 20px;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    box-shadow: 0 5px 20px rgb(52, 52, 52);
    position: fixed;
    width: 100%;
    gap: 100px;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }

  .menu-icon{
    color: black;
  }

  .App-menu-bar a {
    color: rgb(60, 60, 60);
    text-decoration: none;
    transition: color .5s;
  }

  #sidebar a {
    color: rgb(60, 60, 60);
    text-decoration: none;
    transition: color .5s;
  }

  .App-link {
    color: #2c58b7;
  }

  .login-button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: #000;
    padding: 8px 24px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;

    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    line-height: normal;
  }
  
  #signout {
    border: 1px solid black;
    color: #000;
  }

  @media(max-width: 1280px){
    #sidebar {
      position: fixed;
      margin-top: 10vh;
      top: 0;
      right: 0;
      height: 100vh;
      width: 250px;
      z-index: 999;
      background-color: white;
      box-shadow: -5px 0 20px black;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out;
    }
  }
}