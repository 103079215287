#profile-card-explore{
    background: #1b1b1d;
    border: none;
    border-radius: 10px;
    padding: 25px;
    height: 300px;
    width: 250px;
    margin-inline:initial;
    margin: 10px 0;
    transition: 0.3s;
}

#profile-card-explore *{
    transition: all 0.5s ease;
}

#profile-card-explore:hover{
    cursor: pointer;
    box-shadow: 3px 3px 6px rgba(255, 255, 255, 0.5);
}

.action-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#text-container{
    position: relative;
    text-align: left;
    height: 50%;
}

.business-name-text{
    display: -webkit-box;
    overflow: hidden;
    /* 2 line clamp to ensure support in line-clamp in all browsers */
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    min-height: 14px;
    font-size: 13px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    font-size: 25px;
    color: white;
}

.detail-text{
    font-size: 18px;
    text-align: left;
    color: lightgrey;
}

.business-description-text{
    display: -webkit-box;
    overflow: hidden;
    /* 2 line clamp to ensure support in line-clamp in all browsers */
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: lightgrey;
}

.payment-text{
    color: white;
    font-size: 30px;
    margin: 0;
    float: right;
}

#link-to-apply{
    padding: 10px 20px;
    background: linear-gradient(71.15deg, var(--c1, #ff5f6d) 13.7%, var(--c2, #ffc371) 86.86%) var(--x, 0)/200%;
    color: white;
    border: none;
    margin: 50px auto;
    border-radius: 8px;
    font-size: 16px;
    transition: 1s;
}

#link-to-apply:hover{
    cursor: pointer;
    --x: 100%;
    --c1: #ffc371;
    --c2: #ff5f6d;
}

.locationAndType{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    gap: 0;
}

.detail{
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: 40px;
}

.details{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.detailIcons{
    display: flex;
    align-items: center;
}

.detail-text{
    margin: 0;
    padding-left: 10px;
    word-break: break-word;
}

@media (max-width: 400px){
    #profile-card-explore{
        position: relative;
    }

    .business-name-text{
        font-size: 30px;
        margin: 0;
    }
    .business-description-text{
        display: none;
    }

    .detail{
        display: none;
    }

    .priceContainer{
        position: absolute;
        left: 50%;
        bottom: 10%;
        transform: translateX(-50%);
    }

    .payment-text{
        font-size: 38px;
    }
}

@media (max-width: 195px){
    .payment-text{
        font-size: 20px;
    }
}

@media (prefers-color-scheme: light){
    #profile-card-explore{
        background: white;
    }
    
    #profile-card-explore:hover{
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
    }

    .business-name-text{
        color: black;
    }
    
    .business-location-text{
        color: black;
    }
    
    .business-description-text{
        color: black;
    }
    
    .payment-text{
        color: black;
    }

    .detail-text{
        color: rgb(60, 60, 60);
    }
}