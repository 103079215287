.login-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #1b1b1d; /* Dark background color */
  border-radius: 12px; /* Rounded corners for the container */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff; /* White text color */
  margin: 5vh auto auto;
  margin-bottom: 5vh;
/* this makes the footer on login page to be at the bottom instead of floating with blank space underneath */
  /* margin-bottom: 15vh; */
}

.login-container h1 {
  text-align: center;
}

.error-message {
  color: red; /* Error text color */
  font-size: 14px; /* Font size for the error message */
  display: none; /* Hide the error message by default */
  text-align: left;
}

#dont-have-account-text {
  font-size: 0.875em;
  margin-top: 10px;
  margin-bottom: 0px;
}

#dont-have-account-link {
  font-size: 0.875em;
  margin-top: -10px;
}

.welcome-message {
  color: grey;
  margin-top: 13px;
  margin-bottom: 25px;

}

#forgot-password-text {
  color: lightblue;
  text-decoration: none;
  margin-top: -10px;
}

#no-account-link {
  color: lightblue;
  text-decoration: none;
}

#login-title-text {
  margin-bottom: 0;
}
 
.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pass-and-passerror {
  position: relative;
}

.password-icon {
  position: absolute;
  cursor: pointer;
  padding-top: 10px;
}
  
#student-submit{
  padding: 12px;
  background: linear-gradient(to bottom right, var(--c1, #bfa4f0), var(--c2, #7fc1f7) ) var(--x, 0)/200%;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  transition: 0.5s;
}

#student-submit:hover{
  --x: 100%;
  --c1: #7fc1f7;
  --c2: #bfa4f0;
}

#business-submit{
  padding: 12px;
  background: linear-gradient(to bottom right, var(--c1, #bfa4f0), var(--c2, #7fc1f7) ) var(--x, 0)/200%;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  transition: 0.5s;
}

#business-submit:hover{
  --x: 100%;
  --c1: #7fc1f7;
  --c2: #bfa4f0;
}

#login{
  padding: 12px;
  background-color: var(--c, #1990ff);
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

#login:hover{
  --c: #187ad6;
}

.login-form button:hover {
  cursor: pointer;
}

#signup {
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  color: black;
}

.underline {
  text-decoration: underline;
}

#line-break {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1b1b1d inset;
  box-shadow: 0 0 0 30px #1b1b1d inset;
  caret-color: grey;
  -webkit-text-fill-color: white;
  background-color: transparent;
}

.passwordInput{
  display: flex;
  align-items: center;
}

@media (prefers-color-scheme: light) {
  .login-container {
    background-color: white;
    color: black;
  }

  #email, #password {
    background-color: white;
    color: black;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
    caret-color: grey;
    -webkit-text-fill-color: black;
    background-color: transparent;
  }

  #forgot-password-text {
    color: -webkit-link;
  }
  
  #no-account-link {
    color: -webkit-link;
  }
}
