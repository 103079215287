/* Two-column layout */
#two-cols {
    display: flex;
    justify-content: center;
    gap: 10vw;
    color: black;
    padding-top: 40px;
}

/* Default to dark mode */
.column {
    position: relative;
    background-color: #1b1b1d;
    color: white;
    border-radius: 15px;
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
}

.accordianDetails {
    text-align: left;
}

.accordion {
    margin-bottom: 40px;
}

#title-of-signup {
    padding: 5px;
}

.column:hover{
    cursor: pointer;
}

.column h1 {
    padding-bottom: 10px;
    position: static;
    margin: 0;
}

.points {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 10px;
}

.faq-signup{
    margin: 80px 0 50px 0;
    font-weight: bold;
}

.accordion {
    margin-top: 30px;
    max-width: 1000px; 
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.question {
    font-size: 20px;
    font-weight: lighter;
    margin-bottom: 20px;
}

#title {
    font-size: 2.5rem;
}

.faq {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Default to dark mode */
.sign_up_button {
    font-weight: bold;
    border: 1px;
    border-color: white;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 20px;
}

.sign_up_button:hover {
    cursor: pointer;
}

#student-sign-up{
    background: linear-gradient(to bottom right, var(--c1, #bfa4f0), var(--c2, #7fc1f7) ) var(--x, 0)/200%;
    color: white;
    transition: 0.5s;
}

#student-sign-up:hover{
    --x: 100%;
    --c1: #7fc1f7;
    --c2: #bfa4f0;
}

#business-sign-up{
    background: linear-gradient(to bottom right, var(--c1, #bfa4f0), var(--c2, #7fc1f7) ) var(--x, 0)/200%;
    color: white;
    transition: 0.5s;
}

#business-sign-up:hover{
    --x: 100%;
    --c1: #7fc1f7;
    --c2: #bfa4f0;
}

/* not working */
.panel-header{
    background-color: #1b1b1d;
    color: white;
}

.column-for-cards {
    width: 90vw;
    max-width: 395px;
    text-align: left;
}

.sign_up_button {
    text-align: center;
    margin-top: 20px;
}

.heading-for-studentbusiness-card {
    text-align: center;
}

.sign-up-faq{
    max-width: 800px;
    margin: 0 auto;
}

@media (max-width: 877px) {
    #two-cols {   
        flex-direction: column;
        align-items: center;
    }
}

@media (prefers-color-scheme: light) {
    .column {
        position: relative;
        background-color: white;
        color: black;
        border-radius: 15px;
        padding: 50px 50px;
    }

    /* not working */
    .panel-header{
        background-color: blue;
        color: red;
    }
}